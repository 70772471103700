import './App.css';
import Hero from './Components/Hero';
import CTA from './Components/CTA';
import Footer from './Components/Footer';
import Features from './Components/Features';
import Scanner from './Components/Scanner';
import Notifications from './Components/Notifications';

function App() {
  return (
    <div className="App">
      <Hero />
    </div>
  );
}

export default App;
