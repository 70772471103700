/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'


export default function Example() {
  return (
    <body class="h-screen bg-gradient-to-b from-ppBlue to-ppBlue " >    
    </body> 
  )
}
